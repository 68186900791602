import * as React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { Card, CardContent, Container, Typography } from '@material-ui/core'
import { useStyles } from './notFound.css'
import Img from 'gatsby-image'

const NotFound: React.FC = () => {
    const classes = useStyles()
    const {
        file: {
            childImageSharp: { fixed: logo },
        },
    } = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "logo.png" }) {
                childImageSharp {
                    fixed(width: 300) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)
    return (
        <div className={classes.root}>
            <Link to="/" className={classes.logoContainer}>
                <div className={classes.logo}>
                    <Img fixed={logo} alt="OnVaAuResto" />
                </div>
            </Link>
            <Container fixed maxWidth="sm">
                <Card>
                    <CardContent className={classes.card}>
                        <Typography className={classes.title} variant="h1">
                            404
                        </Typography>
                        <Typography className={classes.subTitle} variant="h2">
                            Page introuvable
                        </Typography>
                        <div className={classes.action}>
                            <Link to="/">Retourner à la page d'accueil</Link>
                        </div>
                    </CardContent>
                </Card>
            </Container>
        </div>
    )
}

export default NotFound

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingBottom: theme.spacing(3),
        },
        logoContainer: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(4),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        title: {
            fontSize: '6em',
        },
        subTitle: {
            fontSize: '3em',
        },
        card: {
            display: 'flex',
            padding: theme.spacing(2),
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        },
        action: {
            marginTop: theme.spacing(4),
        },
        logo: {
            'padding': theme.spacing(2),
            '& > img': {
                width: '100%',
                maxWidth: '16rem',
            },
        },
    }),
)

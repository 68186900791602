import * as React from 'react'
import Layout from '../components/layout'
import NotFound from '../components/notFound'

const PageNotFound: React.FC = () => {
    return (
        <Layout>
            <NotFound />
        </Layout>
    )
}

export default PageNotFound
